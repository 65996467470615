import React from 'react';
import $$ from 'cmn-utils';
import {connect} from 'dva';
import {Layout} from 'antd';
import {Switch, routerRedux} from 'dva/router';
import NavBar from "../components/NavBar/NavBar";
import './styles/basic.less';
import TopBar from "../components/TopBar/TopBar";
import BaseComponent from "../components/BaseComponent";

const {Content, Header} = Layout;

/**
 */
@connect(({global}) => ({global}))
export default class HomeLayout extends BaseComponent {
    constructor(props) {
        super(props);
        const user = $$.getStore('userInfo', {});

        this.state = {
            user: user ? user : {}
        };

        props.dispatch({
            type: 'global/getValueMap',
            filterRequest: resp => this.filterRequest(resp)
        });
    }

    componentWillMount() {
        // 检查有户是否登录
        const token = $$.getStore('token');
        if (!token) {
            this.props.dispatch(routerRedux.replace('/sign/login'));
        }
    }

    render() {
        const {user} = this.state;
        const {routerData} = this.props;
        const {childRoutes} = routerData;

        return (
            <Layout className="home-layout page">
                <Header>
                    <NavBar user={user} router={this.props}/>
                </Header>
                <Content>
                    <Switch>{childRoutes}</Switch>
                </Content>
            </Layout>
        );
    }
}
