import {createRoutes} from '@/utils/core';
import UserLayout from '@/layouts/UserLayout';
import NotFound from './Pages/404';
import Page500 from './Pages/500';
import Login from './Login';
import Business from './Business';
import Print from './Print';
import DrawOfPage from './DrawOfPage';
import Blank from './Blank';
import HomeLayout from "../layouts/HomeLayout";

/**
 * 主路由配置
 *
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */
const routesConfig = app => [
    {
        path: '/sign',
        title: '登录',
        indexRoute: '/sign/login',
        component: UserLayout,
        childRoutes: [
            Login(app),
            NotFound()
        ]
    },
    {
        path: '/draw',
        title: '抽签',
        indexRoute: '/draw/index',
        component: UserLayout,
        childRoutes: [
            DrawOfPage(app),
            NotFound()
        ]
    },
    {
        path: '/print',
        title: '打印',
        indexRoute: '/print/index',
        component: UserLayout,
        childRoutes: [
            Print(app),
            NotFound()
        ]
    },
    {
        path: '/',
        title: '系统中心',
        component: HomeLayout,
        indexRoute: '/homepage',
        childRoutes: [
            Business(app),
            Blank(app),
            Page500(),
            NotFound(),
        ]
    },
];

export default app => createRoutes(app, routesConfig);
