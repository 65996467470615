import React, {Component} from 'react';
import {Menu, Avatar} from 'antd';
import {Link, routerRedux} from 'dva/router';
import Icon from '../Icon';
import './style/index.less';
import commonUtils from "../../utils/commonUtils";
import $$ from "cmn-utils/lib/index";
import {connect} from "dva/index";

/**
 * 其本本局头部区域
 */
@connect(({global}) => ({global}))
class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastKey: this._getNavKey(this.props.router.location.pathname)
        };
    }

    componentWillReceiveProps(nextProps) {
        const lastKey = this._getNavKey(nextProps.router.location.pathname);

        this.setState({
            lastKey: lastKey
        });
    }

    _getNavKey = pathname => {
        let paths = pathname.split('/')
        return '/' + paths[1]
    };

    _onSelect = openKeys => {
        this._routerRedux(openKeys.key);
    };

    _routerRedux(path) {
        if (commonUtils.isEmptyString(path)
            || path == this.state.lastKey)
            return;

        this.props.router.dispatch(routerRedux.replace(path));
        // this.setState({lastKey: path})
    }

    render() {
        const {user, global} = this.props;
        const {navBarKey} = global;

        return (
            <header className='navbar'>
                <div className="navbar-bg">
                    <div className="title">动迁选房平台</div>
                    <div className="empty"/>
                    <div className="bg-right">
                        <Link to="/sign/login" className="navbar-lia">
                            <Icon type="poweroff"/>
                        </Link>
                        <div className="name">{user.name}</div>
                        <a className="navbar-lia">
                            <Avatar src={require('assets/images/avatar.jpeg')}>
                                {user.name}
                            </Avatar>
                        </a>
                    </div>
                </div>
            </header>
        );
    }
}


export default NavBar;

// {/*<nav className='nav'>*/}
//     {/*<ul>*/}
//         {/*<li><Link to="/">首页</Link></li>*/}
//         {/*<li><Link to="/business">业务办理</Link></li>*/}
//         {/*<li><Link to='/blank'>数据统计</Link></li>*/}
//         {/*<li><Link to="/500">通知系统</Link></li>*/}
//         {/*<li><Link to="/business/businessindex">系统设置</Link></li>*/}
//     {/*</ul>*/}
// {/*</nav>*/}
