/**
 */
function isEmptyString(string) {
    return !string || string.length < 1;
}

/**
 */
function isEmptyList(list) {
    return !list || list.length < 1;
}

/**
 */
function isEmptyObj(obj) {
    let arr = Object.getOwnPropertyNames(filterParams(obj));
    return arr.length == 0;
}

/**
 * 是否是手机号码
 */
function isNoMobile(phone) {
    return !phone || !(/^1\d{10}$/.test(phone));
}
/**
 * 是否是手机号码
 */
function isNoAZNum(string) {
    return !string || !(/^[0-9]+$/.test(string));
}

/**
 * 是否是固定电话
 */
function isNoPhone(phone) {
    return !phone || !((/^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/.test(phone)) || (/^(\d{7,8})?$/.test(phone)));
}

/**
 * 是否是邮政编码
 */
function isNoPostalCode(obj) {
    return !obj || !(/^[1-9]{1}[0-9]{5}$/.test(obj));
}

function chkPrice(obj) {//正数
    obj = obj.replace(/[^\d.]/g, "");
    //必须保证第一位为数字而不是.
    obj = obj.replace(/^\./g, "");
    //保证只有出现一个.而没有多个.
    obj = obj.replace(/\.{2,}/g, ".");
    //保证.只出现一次，而不能出现两次以上
    obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    return obj;
}

function chkPositiveInt(obj) {//正整数
    obj = obj + ''
    obj = obj.replace(/^(0+)|[^\d]+/g, '');
    return obj;
}

/**
 * 获取dotNum位小数，默认4位
 */
function getPrice(obj, dotNum = 4) {
    return parseFloat(getPriceString(obj, dotNum))
}

function getPriceString(obj, dotNum = 4) {
    if (isEmptyString(obj))
        return parseFloat('0').toFixed(dotNum)

    obj = obj + ".00000"

    let isNegative = obj.startsWith('-')

    let after = chkPrice(obj)

    if (isEmptyString(after))
        return parseFloat('0').toFixed(dotNum)

    return parseFloat((isNegative ? '-' : '') + after).toFixed(dotNum)
}

function isObjectValueEqual(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]

        var propA = a[propName]
        var propB = b[propName]
        if (propA !== propB) {
            if ((typeof (propA) === 'object')) {
                if (this.isObjectValueEqual(propA, propB)) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }
    return true;
}

//这个方法不会影响原来的对象，而是返回一个新对象
function filterParams(obj) {
    let _newPar = {};
    for (let key in obj) {
        //如果对象属性的值不为空，就保存该属性（这里我做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。）
        if ((obj[key] === 0 || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
            //记录属性
            _newPar[key] = obj[key];
        }
    }
    //返回对象
    return _newPar;
}
//返回一get字符串
function getParamsString(params) {
    const str = [];
        for (let p in params) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
        }
        let body = null;
        if (str.length > 0) {
            body = str.join("&");
        }
    return body;
}

//判断是否有空参数 filter【】 为需要判断字段数组
function objHasNull(obj,filter=[]) {
    for (let key in obj) {
        if (filter.indexOf(key)!=-1 && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') === '') {
            return true;
        }
    }
    return false;
}

function log(obj) {
    // return
    console.log(obj)
    // console.log(JSON.stringify(obj))
}

function filter(str) { // 特殊字符转义
    str += ''; // 隐式转换
    str = str.replace(/%/g, '%25');
    str = str.replace(/\+/g, '%2B');
    str = str.replace(/ /g, '%20');
    str = str.replace(/\//g, '%2F');
    str = str.replace(/\?/g, '%3F');
    str = str.replace(/&/g, '%26');
    str = str.replace(/\=/g, '%3D');
    str = str.replace(/#/g, '%23');
    return str;
}

function formateObjToParamStr(paramObj) {
    const sdata = [];
    for (let attr in paramObj) {
        sdata.push(`${attr}=${filter(paramObj[attr])}`);
    }
    return sdata.join('&');
};

function objEnterObj(old, newObj, isFilter) {
    if (isFilter) return filterParams(Object.assign({}, old, newObj))
    return Object.assign({}, old, newObj);
};

function getStringNum(string, num) {
    let res = string.substring(0, num)
    return res;
};

function sortNumber(a, b) {
    return a.id - b.id
}
function deepClone(old, newObj) {
    newObj = JSON.parse(JSON.stringify(old))
    return newObj;
};

export default {
    isEmptyString,
    isEmptyList,
    isEmptyObj,
    isNoMobile,
    isNoAZNum,
    isNoPhone,
    isNoPostalCode,
    chkPositiveInt,
    chkPrice,
    getPrice,
    getPriceString,
    isObjectValueEqual,
    filterParams,
    getParamsString,
    objHasNull,
    log,
    formateObjToParamStr,
    objEnterObj,
    getStringNum,
    sortNumber,
    deepClone,
}
