import moment from 'moment';
import $$ from "cmn-utils";
import * as Address from "./net/address";
import config from "../config";
import commonUtils from "./commonUtils";


/**
 * 获取类型map
 */
function getValueMap() {
    return config.valueMap
}

/**
 * 获取类型map
 */
function getMapValue(key, value) {
    try {
        let map = value ? value : getValueMap()
        return map.get(key)
    } catch {
        return {childMap: new Map()}
    }
}

/**
 * 获取类型map
 */
function getSonMapValue(key, sonKey, value) {
    try {
        let son = getMapValue(key, value).childMap.get(sonKey)
        return son ? son : {name: ''}
    } catch {
        return {name: ''}
    }
}

/**
 * 获取一定间隔的月份
 */
function getMonthDistance(distance) {
    const endMonth = moment().format('YYYY/MM');
    const startMonth = moment().subtract(distance, 'months').format('YYYY/MM');
    return {
        startMonth,
        endMonth
    }
}


/**
 * 获取类型map
 */
function setBusinessOfValueMap(business, i = 0) {
    business.debugId = i + 'debug' + business.id
    let valueMap = getValueMap()
    if (valueMap && valueMap.size) {
        business.company_id = business.id
        business.status_name = '' + getSonMapValue('COMPANY_STATUS', business.status, valueMap).name
        // business.type = business.status
        // business.type_name = business.status_name
    }

    for (let key in business) {
        if (business[key] && key.indexOf('create_time') == -1
            && (key.indexOf('_date') != -1 || key.indexOf('_time') != -1 || key == 'capital_validity_period')
            && business[key].length > 10) {
            business[key] = business[key].substring(0, 10)
        }
    }
}


/**
 * 获取类型map
 */
function setBusinessOfProcess(process, i = 0) {
    let valueMap = getValueMap()
    if (valueMap && valueMap.size) {
        process.type_name = '' + getSonMapValue('BUS_TYPE', process.type, valueMap).name
        process.status_name = '' + getSonMapValue('BUS_STATUS', process.status, valueMap).name

        switch (process.status) {
            case '1':
                process.status_next_name = '提交工商'
                break;
            case '2':
                process.status_next_name = '办理完成'
                break;
            case '3':
                process.status_next_name = '归档'
                break;
        }
    }
}

function setChangeOfProcess(item) {
    let valueMap = getValueMap()
    if (valueMap && valueMap.size) {
        let change_type = item.change_type
        item.change_item_my = getSonMapValue('CHANGE_TYPE', change_type, valueMap)
        item.change_info_obj_my = JSON.parse(item.change_info)
        item.ori_info_obj_my = JSON.parse(item.ori_info)

        item.change_info_value_my = {
            change_info: item.change_info_obj_my,
            change_type,
            ori_info: item.ori_info_obj_my
        }
    }
}


/**
 * 获取类型map
 */
function setMemberOfValueMap(member) {
    let valueMap = getValueMap()
    if (valueMap && valueMap.size) {
        member.investment_amount_old = 0.0000
        member.type_position_name = ''
        member.investment_amount = commonUtils.getPrice(member.investment_amount)
        member.type_name = '' + getSonMapValue('PERSON_TYPE', member.type, valueMap).name
        member.nationality_name = '' + getSonMapValue('NATIONALITY', member.nationality, valueMap).name
        member.political_status_name = '' + getSonMapValue('POLITIC_COUNTENANCE', member.political_status, valueMap).name
        member.education_name = '' + getSonMapValue('EDUCATION_TYPE', member.education, valueMap).name
        member.position_name = '' + getSonMapValue('POSITION', member.position, valueMap).name
        member.identified_type_name = '' + getSonMapValue('IDENTIFIED_TYPE', member.identified_type, valueMap).name
        member.investment_currency_name = '' + getSonMapValue('CURRENCY', member.investment_currency, valueMap).name

        if (member.investment_date && member.investment_date.length > 10) member.investment_date = member.investment_date.substring(0, 10)
    }
}

/**
 * 获取类型map
 */
function setDeptOfValueMap(dept) {
    let valueMap = getValueMap()
    if (valueMap && valueMap.size) {
        dept.dept_type_name = '' + getSonMapValue('DEPT_TYPE', dept.dept_type, valueMap).name
    }
}

/**
 * 获取类型map
 */
function setUserOfValueMap(user) {
    let valueMap = getValueMap()
    if (valueMap && valueMap.size) {
        user.user_type_name = '' + getSonMapValue('USER_TYPE', user.user_type, valueMap).name
    }
}


/**
 * 获取类型map
 */
function setCompanyOfValueMap(company) {
    let valueMap = getValueMap()
    if (valueMap && valueMap.size) {
        company.parent_name = '' + getSonMapValue('COMPANY_TYPE', company.parent_val, valueMap).name
    }
}

/**
 * 按三位分隔数字
 */

function numberFormat(num) {
    //const reg = /(?<=\b(?<!\.)\d*)\B(?=(\d{3})+(?=\b))/g;
    const reg = new RegExp("(?<=\b(?<!\.)\d*)\B(?=(\d{3})+(?=\b))","g");
    return num.toString().replace(reg, ',');
}


export default {
    getValueMap,
    getMapValue,
    getSonMapValue,
    getMonthDistance,

    setCompanyOfValueMap,
    setBusinessOfValueMap,
    setBusinessOfProcess,
    setChangeOfProcess,
    setMemberOfValueMap,
    setDeptOfValueMap,
    setUserOfValueMap,

    numberFormat
}
