import './styles/user.less';
import React from 'react';
import { connect } from 'dva';
import { Layout } from 'antd';
import { Switch } from 'dva/router';
const { Content } = Layout;
import BaseComponent from "../components/BaseComponent";

@connect(({global}) => ({global}))
export default class UserLayout extends BaseComponent {
    constructor(props) {
        super(props); 
        props.dispatch({
            type: 'global/getValueMap',
            filterRequest: resp => this.filterRequest(resp)
        });
    }
  render() {
    const {routerData} = this.props;
    const {childRoutes} = routerData;

    return (
      <Layout className="full-layout user-layout fixed">
        <Content>
          <Switch>{childRoutes}</Switch> 
        </Content>
      </Layout>
    );
  }
}