import $$ from 'cmn-utils';
import modelEnhance from '@/utils/modelEnhance';
import * as Address from "../utils/net/address";
import config from "../config";
import commonUtils from "../utils/commonUtils";
import {routerRedux} from "dva/router";
import netUtils from "../utils/net/netUtils";

const signList = [{id:'1',name:'未抽选'},{id:'2',name:'已锁定'},{id:'3',name:'已签约'}] ;
const roomList = [{id:'大',name:'大'},{id:'中',name:'中'},{id:'小',name:'小'}] ;
const signType = {childList:signList,childMap:new Map(signList.map(i =>[i.id,i]))};
const roomType = {childList:roomList,childMap:new Map(roomList.map(i =>[i.id,i]))};
const groupType = {childList:[],childMap:new Map()}; 

export default modelEnhance({
    namespace: 'global',

    state: {
        navBarKey: 'homepage',
        signType: signType,
        roomType: roomType,
        groupType: groupType,
        valueMap: new Map(),
    },


    subscriptions: {
        setup({history, dispatch}) {
            return history.listen(({pathname}) => {
                let paths = pathname.split('/')
                if (!commonUtils.isEmptyList(paths) && !commonUtils.isEmptyString(paths[1]))
                    dispatch({
                        type: 'changeState',
                        navBarKey: '/' + paths[1],
                    });
            });
        }
    },


    effects: {
        * changeState(payload, {call, put}) {
            yield put({
                type: 'requestSuccess',
                payload: payload,
            });
        },
        * getValueMap({filterRequest, success}, {call, put}) { 
            let data = yield $$.post(Address.GetHoseTypeAll)
                .then(resp => filterRequest(resp))
            if(data){
                let groupType = {}
                groupType.childList = data
                groupType.childMap = new Map(data.map(i =>[i.id,i]))

                yield put({
                    type: 'requestSuccess',
                    payload: {groupType},
                });
            } 
        },
        * addGroup({params,filterRequest, success}, {call, put}) { 
            let data = yield $$.post(Address.HoseGroupCreateOrUpdate,params)
                .then(resp => filterRequest(resp))
            if(data){
                success && success(data)
                yield put({
                    type: 'getValueMap',
                    filterRequest
                });
            } 
        },
        * delGroup({params,filterRequest, success}, {call, put}) { 
            let data = yield $$.get(Address.HoseGroupDelete,params)
                .then(resp => filterRequest(resp))
            if(data){
                success && success(data)
                yield put({
                    type: 'getValueMap',
                    filterRequest
                });
            } 
        },
    },

    reducers: {
        requestSuccess(state, {payload}) {
            return {
                ...state,
                ...payload
            };
        },
    },
});


export function exit() {
    $$.clearStore()
}

