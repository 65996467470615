import {dynamicWrapper, createRoute} from '@/utils/core';

import Transact from './routes/Transact';
import Search from "./routes/Search";
import SearchOfManage from "./routes/SearchOfManage";
import DrawOfPage from "./routes/DrawOfPage";
const routesConfig = app => ({
    path: '/homepage',
    title: '选房系统',
    component: dynamicWrapper(app, [import('./model')], () => import('./components')),
    childRoutes: [
        Transact(app),
        Search(app),
        SearchOfManage(app),
        DrawOfPage(app),
    ]
});

export default app => createRoute(app, routesConfig);
