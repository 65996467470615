import globalUtils from "../globalUtils";
import commonUtils from "../commonUtils";


/**
 *
 */
function getMemberList(req, success) {
    if (req) {
        success && success(req)
        let memberMap = new Map()
        for (let i = 0, len = req.list.length; i < len; i++) {
            globalUtils.setMemberOfValueMap(req.list[i])
            memberMap.set('' + req.list[i].id, req.list[i])
        }
        req.list.sort(sortNumber)


        let shareholderList = req.list.filter(item => item.investment_amount > 0)
        let newMemberList = req.list.filter(item => item.status == '0')
        let oldMemberList = req.list.filter(item => item.status == '1')
        let oldShareholderList = req.list.filter(item => item.investment_amount > 0 && (item.status == '1' || item.status == '2'))

        let amount_sum = 0;
        let amount_sum_after = 0;
        for (var i = 0, len = shareholderList.length; i < len; i++) {
            if (shareholderList[i].status != '0') {
                amount_sum += shareholderList[i].investment_amount;
                shareholderList[i].investment_amount_old = shareholderList[i].investment_amount
            }
            shareholderList[i].type_position_name = shareholderList[i].type == '1' ? '（法人）' : ('（' + shareholderList[i].position_name + '）')
            amount_sum_after += shareholderList[i].investment_amount;
        }

        return {
            memberList: req.list,
            memberMap,
            shareholderList: shareholderList ? shareholderList : [],
            newMemberList: newMemberList ? newMemberList : [],
            oldMemberList: oldMemberList ? oldMemberList : [],
            oldShareholderList: oldShareholderList ? oldShareholderList : [],
            amount_sum, amount_sum_after,
        }
    }
    return
}

/**
 *
 */
function getRoomGroupList(list,signType,groupType){
                for (let item of list) {
                    item.HoseName_my = []
                    for (let house of item.GetHouses) {
                       house.houName_my =  house.Address //+ house.RoomNo
                       item.HoseName_my.push(house.houName_my)
                    }
                    try{
                        item.StatusName = signType.childMap.get(item.Status+'').name
                        item.TypeName = groupType.childMap.get(item.HosTypeID+'').TypeName
                    }catch (e) {
                        console.log(e)
                    }
                }
                return list
}

function sortNumber(a, b) {
    return a.id - b.id
}

export default {
    getMemberList,
    getRoomGroupList,
}
