import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'antd';
import $$ from 'cmn-utils';
import config from '@/config';
import {routerRedux} from "dva/router";
import commonUtils from "../../utils/commonUtils";

class BaseComponent extends React.Component {
    /**
     * 在没有dispatch函数时，如果想要在组件内进行跳转可以用router进行跳转
     */
    static contextTypes = {
        router: PropTypes.object
    };

    valueMap = config.valueMap; // 消息通知
    notice = config.notice; // 消息通知

    /**
     * history api 路由跳转
     */
    get history() {
        return this.context.router.history;
    }

    goPath(path) {
        try {
            this.props.dispatch(routerRedux.replace(path));
        } catch (e) {
            this.history.replace(path)
        }
    }

    filterRequest(resp) {
        const {status, errmsg, data} = resp; 
        if (!status) {
            this.notice.error(errmsg)
            return
        }
        let ret = data ? data : status
        commonUtils.log(ret)
        return ret
    }

    _scrollToAnchor = (anchorName) => {
        try {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        } catch (e) {
            commonUtils.log(e.toString())
        }
    }

    _setFocus(target) {
        if (target)
            try {
                this._scrollToAnchor(target.name)
                target.focus()
            } catch (e) {
                commonUtils.log(e.toString())
            }
    }

    getDefaultState() {
        if (this.defaultState)
            return JSON.parse(this.defaultState)
        else return {}
    }
}

export default BaseComponent;
